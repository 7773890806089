.fullscreenDiv {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.genericError {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    left: 50%;
    top: 50%;
    text-align: center;
}

.headerFont {
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
    font-size: 32px;
    font-weight: 600;
}

.errorFont {
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 42px;
}

@media (min-width: 377px) and (max-width: 767px) {
    .genericError {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 92%;
        left: 50%;
        top: 50%;
        text-align: center;
    }

    .headerFont {
        font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
        font-size: 30px;
        font-weight: 600;
    }

    .errorFont {
        font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
        font-size: 23px;
        font-weight: 400;
        line-height: 30px;
    }


}

@media (min-width: 314px) and (max-width: 376px) {
    .genericError {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        left: 50%;
        top: 50%;
        text-align: center;
    }

    .headerFont {
        font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
        font-size: 30px;
        font-weight: 600;
    }

    .errorFont {
        font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
    }

    ctn-fht {
        min-height: 49vh !important;
    }

}

@media (min-width: 767px) and (max-width: 768px) {

    .genericError {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 63%;
        left: 50%;
        top: 50%;
        text-align: center;
    }

    .headerFont {
        font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
        font-size: 30px;
        font-weight: 600;
    }

    .errorFont {
        font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
        font-size: 28px;
        font-weight: 400;
        line-height: 42px;
    }

}

@media (width: 896px) {
    .genericError {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 63%;
        left: 50%;
        top: 50%;
        text-align: center;
    }

    .headerFont {
        font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
        font-size: 30px;
        font-weight: 600;
    }

    .errorFont {
        font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
        font-size: 22px;
        font-weight: 400;
        line-height: 24.5px;
    }

}