.errorstyle {
    color: grey;
}

.tespan {
    padding-right: 10px;
}

.ml-0 {
    margin-left: 0;
}

@media (min-width: 314px) and (max-width: 768px) {
    .finish {
        min-width: 280px;
    }
}