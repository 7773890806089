
.createArrearsTextArea {
    width: 100% !important;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 10px;
    height: 15rem !important;
    padding: 5px;
}

.textAreaTitle {
    font-weight: 500;
}