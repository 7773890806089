.textarea-notes {
    width: 98%;
    height: 50%;
}

.notes-container {
    margin-top: 16px;
    padding-left: 23px;
    padding-right: 10px;
}

.add-note {
    margin-left: 0px;
    color: rgba(0, 0, 0, .55);
    font-size: 1.5rem;
}

.notes-cration-btn {
    text-align: right !important;
}

.w-option {
    width: 50% !important;
    padding-right: 2rem;
}

.savebtn {
    width: 7rem !important;
    align-items: center !important;
}

.max-height-790 {
    max-height: 790px;
}

[type=button],
[type=reset],
[type=submit],
button {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(0, 155, 170, var(--tw-bg-opacity));
    border-radius: .5rem;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    /* cursor: pointer; */
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
    min-width: 7.5rem;
    padding: .5rem;
    /* text-align: center; */
}

[type=button]:disabled,
[type=submit]:disabled,
button.disabled {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(133, 211, 216, var(--tw-bg-opacity));
    border-radius: .5rem;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    cursor: pointer;
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
    min-width: 7.5rem;
    padding: .5rem;
    /* text-align: left; */
    /* align-items: center; */
}


[type=text]:disabled:focus,
[type=date]:disabled:focus,
textarea:disabled:focus {
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    justify-content: left;
}

[type=button]:disabled:focus,
[type=button]:disabled:hover,
[type=submit]:disabled:focus,
[type=submit]:disabled:hover,
button.disabled:focus,
button.disabled:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 155, 170, var(--tw-bg-opacity))
}

.button-class {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(0, 155, 170, var(--tw-bg-opacity));
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
    min-width: 7.5rem;
    padding: .5rem;
    /* text-align: left; */
}