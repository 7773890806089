.l-mt {
    margin-top: 6em !important;
}



.log {
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
    color: rgba(150, 54, 131);
    font-size: 45px;
    font-weight: 500;
    line-height: 54px;
    text-align: left;
}

.log-in-text {
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
    color: rgba(150, 54, 131);
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;

}

@media (min-width: 314px) and (max-width: 820px) {
    .log {
        font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
        color: rgba(150, 54, 131);
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;

    }

    .log-in-text {
        font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
        color: rgba(150, 54, 131);
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.03em;
    }

    .ml-log-inpg {
        margin-left: 1rem !important;
    }

}