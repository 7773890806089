.section-brder {
    border: 1px solid grey;
    padding: 15px;
    margin-top: 20px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

h1,
h2 {
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
}

h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 0px;
}

p,
ul {
    font-size: 1rem;
}

.mt-20 {
    margin-top: 20px;
}

.ml-10 {
    margin-left: -10px;
}

@media (max-width: 576px) {
    .fin-btn-margin {
        margin-top: 15px;
    }
}