.width-create-to {
    width: 9rem;
}

.short-to {
    margin-top: 1rem !important;
}

.min-ht {
    min-height: 320px;
}

@media (max-width:414px) {
    .grWEmO {
        width: 3.5rem !important;
    }

    .cEWlxS {
        width: 3.5rem !important;
    }
}

.m-left {
    margin-left: 10px;
}

.m-l-sort {
    margin-left: 0.3rem;
}

.ml-sorrt {
    margin-left: 1.2rem;
}



.searchBox {
    width: 129% !important;
}

.ml-7 {
    margin-left: 2rem;
}

.ml-8 {
    margin-left: 3rem;
}

@media(min-width:860px)and (max-width:870px) {
    .ml-XR {
        margin-left: -0.25rem;
    }

}


@media (max-width:720px) {

    .ml-cf {
        margin-left: 2.8rem;
    }

    .px-4s {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
    }

}