.hm-menu {
    background-color: transparent;
    min-width: 0px !important;
    border-radius: 50% !important;
    padding: 10px;
}

.hm-menu :hover {
    background-color: transparent;
}

.pl-nav {
    padding: 0.3rem !important;
}

.eDMS {
    text-decoration-line: underline !important;
    color: rgba(0, 155, 170, 0.9) !important
}

.login-link {
    display: block;
    cursor: pointer;
    font-style: normal;
    padding: 5px;
    color: #000;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (device-width: 360px) {
    .login-link {
        padding: 2px !important;
    }
}

/* GENERAL STYLES */
.visuallyHidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.containerBurger {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

}

.hamburger {
    margin-top: 0 auto;
    width: 28px;
    height: 30px;
    position: relative;
    left: -0.9rem;
}

.hamburger .bar {
    padding: 0;
    width: 28px;
    height: 3px;
    background-color: white;
    display: block;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    position: absolute;
}

.bar1 {
    top: 5px;
}

.bar2,
.bar3 {
    top: 15.5px;
}

.bar3 {
    right: -1px;
}

.bar4 {
    bottom: 0;
}


/* HAMBURGER 3 */
.hamburger3 .bar1 {
    transform-origin: 5%;
}

.hamburger3 .bar4 {
    transform-origin: 5%;
}

.checkbox3:checked+label>.hamburger3>.bar1 {
    transform: rotate(45deg);
    height: 3px;
    width: 34px;
}

.checkbox3:checked+label>.hamburger3>.bar3 {
    transform: rotate(45deg);
    height: 3px;
    background-color: transparent;
}

.checkbox3:checked+label>.hamburger3>.bar2 {
    transform: rotate(-45deg);
    height: 3px;
    background-color: transparent;
}

.checkbox3:checked+label>.hamburger3>.bar4 {
    transform: rotate(-45deg);
    height: 3px;
    width: 34px;
}

.active-link {
    text-decoration-line: underline !important;
    color: rgba(0, 155, 170, 0.9) !important
}