* {
    -ms-user-select: none;
}

*:not(input) {
    -ms-user-select: none;
}

button:hover,
button:focus {
    outline: none;
    cursor: pointer;
}

.apScheduler .ap-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.apScheduler .ap-selected-range {
    font-weight: bold;
}

.apScheduler span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.apScheduler span svg {
    background-color: antiquewhite;
    padding: 5px;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
}

.apScheduler span svg:hover {
    background-color: #e2c196;
}

.apScheduler span svg:active {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.mouse-pointer {
    cursor: pointer;
}

.mt-40 {
    margin-top: 40px;
}

.ml-10 {
    margin-left: -10px;
}

.mt-30 {
    margin-top: 30px;
}

.sc-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    --tw-bg-opacity: 1;
    background-color: rgba(222, 240, 250);
    border-radius: 5px;
    padding: 1rem;
    margin-top: 2rem !important;
}

.sc-wrapper .sc-schedules {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    --tw-bg-opacity: 1;
    background-color: rgba(222, 240, 250);
    margin-bottom: -5px;
}

.sc-wrapper .sc-schedules button {
    padding: 30px;
    width: 220px;
    margin: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    border-radius: 0;
    background-color: #ffffff;
    color: #a0a0a0;
    font-family: AvantGardeGothicITCW01Bk, Myriad Pro, Myriad, Sans-serif !important;
    -ms-user-select: none;
}

.sc-sched-date {
    width: 220px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    --tw-bg-opacity: 1;
    background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
    font-weight: 600;
    text-align: center;
}

.sc-sched-date .text-p {
    display: flex;
    flex-direction: "row";
    align-items: center;
    justify-content: center;
}

.sc-wrapper .sc-schedules .disabled-p {
    padding: 30px;
    width: 220px;
    margin: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0;
    background-color: #ffffff;
    color: #a0a0a0;
    font-family: AvantGardeGothicITCW01Bk, Myriad Pro, Myriad, Sans-serif !important;
    justify-content: center;
    align-items: center;
}

.sc-wrapper .sc-schedules button p {
    margin: 0px;
    text-align: center;
}

.sc-wrapper .sc-schedules button:disabled {
    background-color: #ebebeb;
    cursor: not-allowed;
}

.click-calander {
    color: black;
    border: 1px solid black;
    background-image: url("../../assets/image/checked.png");
    background-repeat: no-repeat;
    background-position: right 4px top 13px;
    background-size: 35px 17px;
}

.btm-button-aling {
    display: flex;
    justify-content: space-between;
}

.avl-txt {
    padding-top: 4px;
    z-index: 1;
}

.avl-left {
    width: 150px;
    padding-left: 0 !important;
    margin-left: 0 !important;
    align-items: start !important;
    text-align: start !important;
    justify-content: left !important;
}

.book-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    margin-right: 5px;
    margin-top: 2rem;
}

.calender-arrow {
    padding: 7px 0 0 6px;
}

.space-right {
    margin: 0px 5px;
}

@media (min-width: 314px) and (max-width: 768px) {
    .sc-mob-wrapper {
        flex-direction: row !important;
    }
    .sc-schedules {
        position: relative;
        width: 100%;
        margin-bottom: -1px;
    }
    .sc-mob-date {
        width: 100% !important;
        height: 60px;
        /* display: block !important; */
        position: absolute !important;
        margin-left: -1px;
        margin-top: 6px;
    }
    .mt-top {
        margin-top: 60px;
        margin-bottom: 6px;
    }
    .wd-1 {
        position: relative;
        top: -12px;
    }
    .cal-btn {
        min-width: 10px !important;
        width: 100% !important;
        margin-left: 11px !important;
        height: 60px;
        margin-bottom: -4px !important;
    }
    .sc-wrapper .sc-schedules button {
        width: 97%;
        align-items: center;
    }
    .sc-wrapper {
        padding-bottom: 1rem;
    }
    .wt1 {
        width: 100%;
    }
}

@media (min-width: 769px) {
    .sc-sched-date {
        width: 23% !important;
        height: 70px;
    }
    .cal-btn {
        min-width: 10px !important;
        width: 22% !important;
        height: 70px;
    }
    .sc-wrapper {
        position: relative;
    }
    .sc-schedules {
        width: 100%;
    }
    .wd-1 {
        position: relative;
        top: -12px;
        font-size: 0.6rem;
    }
    .wt1 {
        width: 100% !important;
        margin-right: -26px !important;
    }
}

@media (min-width: 1024px) {
    .wd-1 {
        font-size: 1rem;
    }
    .wt1 {
        margin-right: -54px !important;
    }
    .sc-wrapper {
        padding-right: 66px;
    }
}

@media (min-width: 1280px) {
    .wt1 {
        margin-right: -82px !important;
    }
}
.pl-3{
    padding-left: .6rem;
}