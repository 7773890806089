.workorderDropdown {
    float: left;
    width: 30%;
    /* padding: 10px; */
    padding-top: .4rem;
    /* padding-left: 10px; */
    padding-right: 10px;
    /* padding-bottom: 10px; */

}

.ht {
    height: 2.5rem;
    background-color: rgba(248, 248, 248, 1)
}
.caselistDropdown {
    float: left;
    width: 30%;
    /* padding: 10px; */
    /* padding-top: 1rem; */
    /* padding-left: 10px; */
    padding-right: 10px;
    /* padding-bottom: 10px; */
}

.wid-create {
    width: 63% !important;
}

.caselistSearch {
    float: left;
    width: 39%;
    /* padding: 10px; */
    height: 2.7rem;
    padding-top: 7px;
    /* padding-left: -10px;
    padding-right: -10px;
    padding-bottom: 10px; */
}

.MuiFormControl-fullWidth {
    height: 2rem;
}


.caselistFilter {
    float: left;
    width: 20%;
    padding: 10px;
}

.JxPho {
    height: 2rem !important;
}

[type=text] {
    min-height: 1.2rem !important;
    /* background-color: #fff; */
    padding: 6px;
}

.ml-cf {
    margin-left: -0.7rem;
}

@media (max-width: 820px) {
    .caselistDropdown {
        float: left;
        width: 100% !important;
        padding-right: 0px;
        padding-bottom: 0px;
        /* padding: 10px; */
    }

    .workorderDropdown {
        float: left;
        width: 100% !important;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    .caselistSearch {
        float: left;
        width: 100% !important;
        /* padding: 10px; */
    }


    .caselistFilter {
        float: left;
        width: 80%;
        padding: 10px;
    }

    .wid-create {
        width: 69% !important;
    }


}

.dskViewColumnCase {
    width: 22%;
}