.attachment {

  cursor: pointer !important;
  /* margin-left: 5px; */
  text-decoration: underline !important;

}

.button-class-send {
  --tw-bg-opacity: 1;
  /* --tw-text-opacity: 1; */
  background-color: rgba(0, 155, 170, var(--tw-bg-opacity));
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
  min-width: 7.5rem;
  /* padding: 1rem; */
  text-align: center;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  margin-left: 2.2rem !important;
  height: 28px !important;
  width: 30px !important;
}


.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  /* background: #fefcfc; */
}

/* Snow Theme */
.app .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.app .ql-editor {
  min-height: 18em;
}

.ql-bold,
.ql-italic,
.ql-underline,
.ql-strike,
.ql-blockquote,
.ql-list,
.ql-indent,
.ql-code-block,
.ql-clean,
.ql-color .ql-picker .ql-color-picker,
.ql-link {
  min-width: 70px !important;
  min-height: 25px !important;

}

.loader {
  height: 750px !important;
}


.mediaHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;
  background-color: rgb(242, 242, 242);
  height: 2.5rem;
  flex-grow: 1;
}

.mediaHeaderName {
  flex-basis: 60%;
}

.tableHeader {
  padding-right: 2rem;
}


.tableRowColor {
  background: #f2efeb;
}

.thumbnail {
  /* height: 50%; */
  width: 3rem !important;
  height: 3rem !important;
  padding: 13px;
  margin-left: 10px;
}

.deleteBtn {
  font-size: 1.2rem !important;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 500;
  border-radius: 2rem;
  font-style: initial;
  color: #000;
  text-align: center;
  cursor: pointer;
}

.highLight {
  color: red;
}

.imgName {
  display: flex;
  align-content: space-between;
  align-items: center;
  margin-left: -2rem;
  width: auto !important;
}

.thumbnailImgName {
  flex-shrink: 0;
  word-wrap: break-word;
  max-width: 80%;
  /* inline-size: 50rem; */
}

.mediaRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
  flex-grow: 1;
}


@media (min-width: 314px) and (max-width: 767px) {
  .ql-picker-label {
    margin-left: -10px !important;
  }

  .w-less {
    width: 99% !important;
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    margin-left: 1.2rem !important;
    height: 28px !important;
    width: 32px !important;
  }

  .ql-bold,
  .ql-italic,
  .ql-underline,
  .ql-strike,
  .ql-blockquote,
  .ql-list,
  .ql-indent,
  .ql-code-block,
  .ql-clean,
  .ql-color .ql-picker .ql-color-picker,
  .ql-link {
    min-width: 3rem !important;
    min-height: 25px !important;

  }

}

@media (min-width:376px) and (max-width:420px) {

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    margin-left: .8rem !important;
    height: 28px !important;
    width: 32px !important;
  }
}

@media (max-width:376px) {

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    margin-left: .5rem !important;
    height: 28px !important;
    width: 32px !important;
  }


}