* {
    box-sizing: border-box;
}


/* Create four equal columns that floats next to each other */
.column {
    float: left;
    width: 15%;
    padding: 10px;
    word-wrap: break-word;
}

.colLocation {
    float: left;
    width: 17%;
    padding: 10px;
    word-wrap: break-word;
}

.columnDes {
    float: left;
    width: 21%;
    padding: 10px;
    word-wrap: break-word;
}

.columnRadio {
    float: left;
    width: 5%;
    padding: 10px;
}


/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.headDiv {
    font-family: AvantGardeGothicITCW01Bk, Myriad Pro, Myriad, Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;

}

.bdyDiv {
    font-family: AvantGardeGothicITCW01Bk, Myriad Pro, Myriad, Sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
    text-align: left;

}

.dupDiv {
    background-color: rgb(242, 242, 242, 1);
    width: 90%;
}

.divRight {
    border-right: 1px solid rgba(128, 128, 128, 1);
    height: 100%;
}

.bordertable {
    border-top: 10px solid rgb(0, 155, 170, 1);
    border-bottom: 1px solid rgba(128, 128, 128, 1);
    border-left: 1px solid rgba(128, 128, 128, 1);
    border-right: 1px solid rgba(128, 128, 128, 1);
    border-radius: .5rem !important;
}

.divLeft {
    margin-left: 0rem
}

.workorderLink {
    cursor: pointer;
    color: rgb(0, 155, 170, 1);
}

.flex-item-left {
    flex: 90%;
}

.flex-item-right {
    flex: 10%;
    margin-top: -7px;
    font-weight: bold;
    font-size: larger;
    cursor: pointer;
    /* margin-right: -1rem; */
}

@media (max-width:820px) {
    .column {
        float: left;
        width: 100%;
        padding: 10px;
        word-wrap: break-word;

    }

    .columnDes {
        float: left;
        width: 100%;
        padding: 10px;
        word-wrap: break-word;
    }

    .dupDiv {
        width: 82%;
    }

    .divRight {
        border-bottom: 1px solid rgba(128, 128, 128, 1);
        border-right: 0px;
        width: 104%;
        height: auto;
        margin-left: -0.7rem !important;
    }

    .mob-div {
        margin-left: -0.7rem !important;
    }

    .divLeft {
        margin-left: 2rem
    }

    .div-mr-left {
        margin-right: -2rem;
    }
}

@media (max-width:512px) {
    .column {
        float: left;
        width: 100%;
        padding: 10px;
        word-wrap: break-word;
    }

    .columnDes {
        float: left;
        width: 100%;
        padding: 10px;
        word-wrap: break-word;
    }

    .dupDiv {
        width: 82%;
    }

    .divRight {
        border-bottom: 1px solid rgba(128, 128, 128, 1);
        border-right: 0px;
        width: 108%;
        height: auto;
        margin-left: -0.7rem !important;
    }

    .mob-div {
        margin-left: -0.7rem !important;
    }

    .divLeft {
        margin-left: 2rem
    }

    .div-mr-left {
        margin-right: -2rem;
    }
}

@media(max-width:376) {
    .divRight {
        border-bottom: 1px solid rgba(128, 128, 128, 1);
        border-right: 0px;
        width: 107.8%;
        height: auto;
        margin-left: -0.7rem !important;
    }

    .mob-div {
        margin-left: -0.7rem !important;
    }
}

@media(max-width:820px) {
    .divLeft {
        margin-left: 2rem
    }
}