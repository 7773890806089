.overlayPopUp {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

.requestStatement {
    position: fixed;
    border-radius: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 10px;
    z-index: 9999;
    max-width: 43rem;
    display: flex;
    color: black;
}

.modalPopUp {
    position: fixed;
    border-radius: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 10px;
    z-index: 9999;
    width: 530px;
    height: 265px;
    display: flex;
}

.modalPopUp2,
.modalPopCancel,
.modalPopUpHome {
    position: fixed;
    border-radius: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 10px;
    padding-bottom: 30px;
    z-index: 9999;
    /* height: 300px; */
    height: auto;
    display: flex;
}

.modalPopUp2 {
    width: 770px;
}

.modalPopCancel {
    width: 468px;
    right: 50% !important;
}

.modalPopUpHome {
    width: 509px;
}

.right-2 {
    right: 0.5rem;
}

.modalButtonContainer {
    display: flex;
    justify-content: space-evenly;
}

.modalPopUpContainer .question {
    text-align: center;
}

#portal {
    position: fixed;
    z-index: 9999;
}

.desk-my-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

@media screen and (orientation:landscape) and (min-device-width: 320px) and (max-device-width: 916px) {
    .requestStatement {
        max-height: 90vh;
        overflow: scroll;
    }
}

@media (max-width:1024px) {
    .requestStatement {
        max-height: 90vh;
        overflow: scroll;
    }
}

@media(min-width:314px) and (max-width: 768px) {
    .modalPopUpContainer {
        width: 100% !important;
    }

    .modalPopUp {
        width: 100% !important;
        /* top: 43%; */
        vertical-align: middle;
        height: auto;
    }

    .modalPopUp2,
    .modalPopUpHome {
        width: 100%;
        vertical-align: middle;
        height: auto;
    }

    .home-css {
        margin-left: 0 !important;
        margin-top: 0 !important;
    }

    .requestStatement {
        max-height: 90vh;
        overflow: scroll;
    }
}

@media(min-width:314px) and (max-width: 576px) {
    .modalPopCancel {
        width: 100%;
        vertical-align: middle;
        height: auto;
    }

    .mob-pad-x {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .desk-my-5 {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }

    .requestStatement {
        max-height: 90vh;
        overflow: scroll;
    }
}

.auto-margin {
    margin: auto;
}

.ok-margin {
    margin-right: -22%;
}

.home-css {
    margin-left: -74px;
    margin-top: -19px;
}
.deleteBtn {
    font-size: 1.2rem !important;
    font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif, Apple Color Emoji, Segoe UI Emoji;
  
    font-weight: 500;
    border-radius: 2rem;
    font-style: initial;
    color: #000;
    text-align: center;
  
    padding-top: 0.5rem;
    padding-right: 2.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.8rem;
  
    cursor: pointer;
  }