#vulQues-textarea {
    width: 98% !important;
    height: 150px !important;
}

textarea {
    margin-top: 20px;
    margin-left: 9px;
    padding: 5px 5px 5px 10px;
}

.error-msg-alingment {
    margin-left: 164px;
}

.vul-teal:focus {
    --tw-ring-inset: var(--tw-empty, /*!*/
    /*!*/
    );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #ffffff;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    border-color: #ffffff;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
    outline: 2px solid transparent;
    outline-offset: 2px;
}

[type=button],
[type=reset],
[type=submit],
button {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(0, 155, 170, var(--tw-bg-opacity));
    border-radius: .5rem;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
    min-width: 12.5rem;
    padding: .75rem 1rem;
    text-align: left
}

textarea:focus {
    --tw-border-opacity: 1;
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 155, 170, var(--tw-ring-opacity));
    border-color: rgba(0, 155, 170, var(--tw-border-opacity))!important;
}

@media (min-width: 314px) and (max-width: 767px) {
    textarea {
        width: 92% !important;
    }
}