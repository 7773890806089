/* body {
    font-family: sans-serif;
} */

input {
    border: 1px solid #999;
    border-radius: 0.3rem;
    padding: 0.5rem;
    width: 300px;
    margin-top: 10px;
}



#high-level-repair {
    margin-top: -3px !important;
}

.no-suggestions {
    color: #000000;
    padding: 00.5rem;
}

.pt-01 {
    padding-top: -10px !important;
}

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    color: #000000;
}

.suggestions option {
    padding: 0.4rem;
}

.suggestions-active {
    color: gray;
    cursor: pointer;
    font-weight: 500;
}

.repair-problems-encounter,
.problemsEncounteringQuestion {
    color: gray;
    font-weight: 400;
}

.suggestions option:hover {
    background-color: #ddd;
    color: #000;
    cursor: pointer;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option {
    font-family: AvantGardeGothicITCW01Bk, Myriad Pro, Myriad, Sans-serif !important;
}

.no-match-global-error-icon {
    background-color: rgba(0, 155, 170);
    border-radius: 9999px;
    width: 67px;
    height: 66px;
    padding-top: 1.1rem;
    text-align: center;
    color: white;
    float: left;
    margin: 0 5 0 0;
    padding-left: 8px;
}

.error-span-space {
    margin-top: 2px;
}

.repair-location-error-aling {
    margin-top: -20px;
}

.qerror-inline {
    display: flex;
    align-items: center;
    flex-direction: row;
}


/* .suggestions option:not(:last-of-type) {
    border-bottom: 1px solid #999;
} */

.border-color:after,
:before {
    --tw-ring-inset: var(--tw-empty, /*!*/
    /*!*/
    );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    font-size: 1.2rem;
}

[type=text] {
    min-height: 1.2rem;
    background-color: #fff;

}

.MuiInputBase-input {
    font-family: AvantGardeGothicITCW01Bk, Myriad Pro, Myriad, Sans-serif !important;
}