.logo {
    width: 2.8rem;
    height: 2.8rem;
    /* top: 931px; */
    /* left: 80px; */
    /* gap: 0px; */
}

.logoLeft {
    margin-left: -6px;
}

.bg-teal {
    background: rgba(38, 156, 168, 1);

}

.bg-dl-teal {
    --tw-bg-opacity: 1;
    background-color: rgba(159, 217, 221, 1);


}