.spinner {
    display: inline-block;
    width: 60px;
    height: 60px;
  }
  .spinner:after {
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    margin: 3px;
    border-radius: 50%;
    border: 2px solid #009baa;
    border-color: #009baa transparent #009baa transparent;
    animation: spinner 1.2s linear infinite;
  }
  .overlayContent {
    height: 30px;
  
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .overlayContent span {
    margin: 4px 0 0 10px;
    color: #009baa;
    font-size: 1.5rem;
  }
  #UploadingOverlay {
    background: rgba(0, 0, 0, 0.1);
    color: #009baa;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: right;
    margin-right: 0;
    padding-right: 100px;
    padding-top: 9px;
  }
  #UploadingOverlay .overlayContainer {
    margin: 40%;
  }
  
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  