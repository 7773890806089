.memo-root-3,
.jss-3 {
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.mlshort {
    margin-left: -8rem;
}

.min-ht {
    min-height: 320px;
}

.dskViewColumn {
    width: 25%;
}

.dskViewColumn1 {
    width: 28%;
}

.dskViewColumn2 {
    width: 21.5%;
}

.calendar {
    margin-left: -2.8rem !important;
    margin-top: 0.6rem !important;
    height: 2.375rem !important;
    background-color: rgba(248, 248, 248, 1) !important;
    border-radius: 0% !important;
}

.input-group-text {
    border: 0px solid !important;
}

input.left::-webkit-date-and-time-value {
    text-align: left;
}

@media (max-width:414px) {
    .grWEmO {
        width: 3.5rem !important;
    }

    .cEWlxS {
        width: 3.5rem !important;
    }
}

.m-left {
    margin-left: 10px;
}

.m-l-sort {
    margin-left: 0.3rem;
    margin-right: o.5rem;
}

.ml-sorrt {
    margin-left: 1.2rem;
}

.srt-wid {
    width: 87%;
    margin-left: 0rem !important
}

.backToTop {
    width: 6.5rem;
    height: 6rem;
    position: fixed;
    bottom: 0.5rem;
    right: 3rem;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
}

.backToTopDesktop {
    width: 6.5rem;
    height: 6rem;
    position: fixed;
    bottom: 10rem;
    right: 3rem;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
}

.topPosition {
    padding-top: 4px;
}

@media(min-width:860px)and (max-width:870px) {
    .ml-XR {
        margin-left: -0.25rem;
    }

}



@media (max-width:820px) {
    .srt-wid {
        width: 80%;
        margin-left: 0rem !important
    }


    .ml-cf {
        margin-left: 2.8rem !important;
    }

    .backToTop {
        width: 6.5rem;
        height: 6rem;
        position: fixed;
        bottom: 0.5rem;
        right: 1rem;
        z-index: 99;
        font-size: 18px;
        border: none;
        outline: none;
        color: white;
        cursor: pointer;
        padding: 15px;
        border-radius: 4px;
    }
}

@media (max-width:720px) {
    .backToTopDesktop {
        width: 6.5rem;
        height: 6rem;
        position: fixed;
        bottom: 14.5rem;
        right: 1rem;
    }

    .backToTop {
        width: 6.5rem;
        height: 6rem;
        position: fixed;
        bottom: 0.5rem;
        right: 1rem;
        z-index: 99;
        font-size: 18px;
        border: none;
        outline: none;
        color: white;
        cursor: pointer;
        padding: 15px;
        border-radius: 4px;
    }
}

@media (min-width:521)and(max-width:820) {
    .cal-wid {
        width: 55%
    }

    .cal-to-wid {
        width: 57%
    }

    .crt-wid {
        width: 65%
    }


}

@media (max-width:520) {
    .cal-to-wid {
        width: 80%
    }

    .cal-wid {
        width: 80%
    }


}


@media (min-width:920) and(max-width:1300) {
    .Spc-ml {
        margin-left: 2rem !important;
    }
}