textarea:focus {
    --tw-ring-color: transparent;
}

#outlined-textarea {
    height: 9rem;
}
.adhesive-container {
    padding-left: 11px;
    margin-top: 6px;
}
.bold-text {
    font-weight: 900;
    margin: 0;
}
.mr-k{
    width: 20px;
    display: inline-block;
}