span {
    margin-left: 10px;
}

.cust-rad {
    margin-bottom: 10px !important;
}

.margin-text {
    margin-left: 10.2rem !important;
}

.mtop {
    margin-top: 20px;
}

.error-inline {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.error-top-align {
    margin-top: 5px;
}

.error-left-padd {
    padding-left: 10px !important;
}

.customer-details-head {
    font-size: 1.5rem;
    font-family: AvantGardeGothicITCW01Bk, Myriad Pro, Myriad, Sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media (min-width: 314px) and (max-width: 768px) {
    .margin-text {
        margin-left: 0rem !important;
    }
}

#custDetails-div1 input[type=text]:focus {
    border: 1px solid grey !important;
}

#custDetails-label2,
#options label {
    display: flex;
}

@media (max-width: 415px) {
    #custDetails-label2 .MuiTypography-root {
        position: relative !important;
        top: 11px !important;
    }
}