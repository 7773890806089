.mediaHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem;
    background-color: rgb(242, 242, 242);
    height: 2.5rem;
    flex-grow: 1;
  }
  
  .mediaHeaderName {
    flex-basis: 60%;
  }
  
  /* .mediaHeaderSize {
    margin-left: 42rem;
  } */
  
  .mediaRowContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2rem;
    flex-grow: 1;
  }
  
  .progressBar {
    width: 25%;
  }
  
  .mediaName {
    /* width: 35rem; */
    flex-grow: 6;
  }
  
  .deleteButtonContainer {
    margin-left: 8rem;
    margin-right: 1.5rem;
  }
  
  .deleteButton {
    cursor: pointer;
  }
  
  .form-file-upload {
    height: 16rem;
    max-width: 100%;
    text-align: center;
    position: relative;
    margin-bottom: 1rem;
  }
  
  .input-file-upload {
    display: none;
  }
  
  .label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }
  
  .label-file-upload-drag-active {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #e1f2fb;
    width: 100%;
  }
  
  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: "Oswald", sans-serif;
    background-color: transparent;
  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }
  
  .drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  
  .disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  
  .deleteBtn {
    font-size: 1.2rem !important;
    font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
      sans-serif, Apple Color Emoji, Segoe UI Emoji;
  
    font-weight: 500;
    border-radius: 2rem;
    font-style: initial;
    color: #000;
    text-align: center;
  
    padding-top: 0.5rem;
    padding-right: 2.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.8rem;
  
    cursor: pointer;
  }
  
  .hideMobViewText {
    --tw-text-opacity: 1;
    color: rgba(144, 150, 152, var(--tw-text-opacity));
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  
  .test {
    padding-top: 0.5rem;
    padding-right: 0.8rem;
    padding-bottom: 0.5rem;
    padding-left: 0.8rem;
    margin-right: 0rem;
  }
  
  .strongHeader {
    padding-right: 1.1rem;
  }
  
  .sizeStyle {
    margin-right: 1.7rem;
  }
  
  .mediaSize {
    padding-right: 3.5rem !important;
  }
  
  .mediaSizeDevice {
    padding-right: 3.5rem !important;
  }
  
  .mediaAction {
    padding-right: 0rem !important;
  }
  .thumbnail {
    /* height: 50%; */
    width: 3rem !important;
    height: 3rem !important;
  }
  
  .imgName {
    display: flex;
    align-content: space-between;
    align-items: center;
    margin-left: -2rem;
  }
  
  .thumbnailImgName {
    flex-shrink: 0;
    word-wrap: break-word;
    max-width: 80%;
    inline-size: 50rem;
  }
  
  .tableHeader {
    padding-right: 2rem;
  }
  
  .tableHeaderSize {
    margin-left: 2.7rem !important;
  }
  
  .overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.1); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }
  
  .tableRowColor {
    background: #f2efeb;
  }
  
  .uploadMediaNo {
    display: block;
  }
  
  @media (min-width: 314px) and (max-width: 912px) {
    .mediaHeader {
      padding: 0rem 1rem;
    }
  
    .hideMobViewText {
      --tw-text-opacity: 1;
      color: rgba(144, 150, 152, var(--tw-text-opacity));
      font-size: 1.25rem;
      line-height: 1.75rem;
      display: none;
    }
    .mediaHeaderName {
      flex-basis: 72%;
    }
  
    .mediaRowContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 2rem;
    }
  
    .deleteButtonContainer {
      margin-left: 0rem;
      margin-right: 0rem;
    }
  
    .thumbnail {
      /* height: 50%; */
      max-width: 3rem !important;
      height: 3rem !important;
    }
    .mediaSize {
      padding-right: 4.5rem !important;
    }
  
    .thumbnailImgName {
      word-wrap: break-word;
      max-width: 100%;
      inline-size: 10rem;
      /* overflow: hidden; */
    }
  
    .tableHeaderSize {
      margin-left: 2.6rem !important;
    }
  
    .mediaAction {
      padding-right: 0rem !important;
    }
  
    .label-file-upload {
      height: 40%;
      display: contents;
    }
    .form-file-upload {
      height: auto;
    }
  }
  
  @media (min-width: 768px) and (max-width: 819px) {
    .hideMobViewText {
      --tw-text-opacity: 1;
      color: rgba(144, 150, 152, var(--tw-text-opacity));
      font-size: 1.25rem;
      line-height: 1.75rem;
      display: none;
    }
    .mediaSize {
      padding-right: 2.3rem !important;
    }
    .mediaSizeDevice {
      padding-right: 2.5rem !important;
    }
  
    .thumbnailImgName {
      word-wrap: break-word;
      max-width: 80%;
      inline-size: 16rem;
    }
    .label-file-upload {
      height: 40%;
      display: contents;
    }
    .form-file-upload {
      height: auto;
    }
    .uploadMediaNo {
      display: block;
      margin-left: 95%;
      margin-top: -25px;
    }
  }
  
  @media (min-width: 820px) and (max-width: 1180px) {
    .hideMobViewText {
      --tw-text-opacity: 1;
      color: rgba(144, 150, 152, var(--tw-text-opacity));
      font-size: 1.25rem;
      line-height: 1.75rem;
      display: none;
    }
    .label-file-upload {
      height: 40%;
      display: contents;
    }
    .form-file-upload {
      height: auto;
    }
    .uploadMediaNo {
      display: block;
    }
  }
  
  @media (max-width: 280px) {
    .hideMobViewText {
      --tw-text-opacity: 1;
      color: rgba(144, 150, 152, var(--tw-text-opacity));
      font-size: 1.25rem;
      line-height: 1.75rem;
      display: none;
    }
    .mediaHeaderName {
      flex-basis: 50%;
    }
  
    .mediaRowContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 2rem;
    }
  
    .deleteButtonContainer {
      margin-left: 0rem;
      margin-right: 0rem;
    }
  
    .thumbnail {
      /* height: 50%; */
      max-width: 3rem !important;
      height: 3rem !important;
    }
    .mediaSize {
      padding-right: 3.5rem !important;
    }
  
    .mediaAction {
      padding-right: 0rem !important;
    }
  }
  
  /*  */
  @media (min-width: 993px) and (max-width: 1198px) {
    .mediaHeaderName {
      flex-basis: 50%;
    }
  
    .thumbnailImgName {
      word-wrap: break-word;
      max-width: 80%;
      inline-size: 16rem;
      /* overflow: hidden; */
    }
    .label-file-upload {
      height: 40%;
      display: contents;
    }
    .form-file-upload {
      height: auto;
    }
  }
  
  @media (min-width: 912px) and (max-width: 992px) {
    .mediaHeaderName {
      flex-basis: 30%;
    }
    .thumbnailImgName {
      word-wrap: break-word;
      max-width: 80%;
      inline-size: 16rem;
      /* overflow: hidden; */
    }
    .label-file-upload {
      height: 40%;
      display: contents;
    }
    .form-file-upload {
      height: auto;
    }
  }
  
  @media (min-width: 360px) and (max-width: 414px) {
    .hideMobViewText {
      --tw-text-opacity: 1;
      color: rgba(144, 150, 152, var(--tw-text-opacity));
      font-size: 1.25rem;
      line-height: 1.75rem;
      display: none;
    }
    .mediaHeaderName {
      flex-basis: 45%;
    }
    .thumbnail {
      /* height: 50%; */
      max-width: 2rem !important;
      height: 2rem !important;
    }
    .mediaSize {
      padding-right: 0.3rem !important;
    }
  
    .mediaSizeDevice {
      padding-right: 2.5rem !important;
    }
  
    .mediaAction {
      padding-right: 0rem !important;
    }
    .thumbnailImgName {
      word-wrap: break-word;
      max-width: 100%;
      inline-size: 8rem;
    }
    .label-file-upload {
      height: 40%;
      display: contents;
    }
  
    .form-file-upload {
      height: auto;
    }
    .uploadMediaNo {
      display: block;
      margin-left: 92%;
      margin-top: -25px;
    }
  }
  