.msg-wrapper {
  white-space: pre-wrap;
}

.blockingMessage {
display:none;
}  

.unblockingMessage {
display: block;
}

.div-txt-newline {
white-space: pre-line;
}

@media (max-width: 375px) {
  .textarea-mob {
    background: transparent !important;
    width: 100% !important;
    height: 150px !important;
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
    
  }
}


@media (max-width: 390px) {
  .textarea-mob {
    background: transparent !important;
    width: 100% !important;
    height: 150px !important;
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }
}


@media (max-width: 412px) {
  .textarea-mob {
    background: transparent !important;
    width: 100% !important;
    height: 150px !important;
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }
}

