/* .MuiSelect-selectMenu {
    width: 300px !important;
    border-radius: 5px !important;
    border: 1px solid grey !important;
  }
  
  span {
    margin-left: 10px;
  }
  
  .cust-rad {
    margin-bottom: 10px !important;
  }
  
  .margin-text {
    margin-left: 10.2rem !important;
  }
  
  .mtop {
    margin-top: 20px;
  }
  .error-inline {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .error-top-align {
    margin-top: 5px;
  }
  .error-left-padd {
    padding-left: 10px !important;
  }
  
  .customer-details-head {
    font-size: 1.5rem;
    font-family: AvantGardeGothicITCW01Bk, Myriad Pro, Myriad, Sans-serif;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  @media (min-width: 314px) and (max-width: 768px) {
    .margin-text {
      margin-left: 0rem !important;
    }
  }
   */

.inpt {
    border: 1px solid #999;
}

.inpt:focus {
  border: 1px solid #009baa!important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

