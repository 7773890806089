ul {
    list-style-type: none;
  }
  
  .pdf-alert {
    margin-left: 0rem;
    width: 100%;
  }
  
  .content {
    /* width: 10%; */
    height: 10%;
    margin-left: -3rem;
    cursor: pointer;
  }
  
  .title {
    margin-left: 2rem;
  }
  
  .imgName {
    flex-shrink: 0;
    word-wrap: break-word;
    inline-size: 65rem;
    height: inherit;
  }

  .imgNameMedia {
    flex-shrink: 0;
    word-wrap: break-word;
    inline-size: 65rem;
    height: inherit;
  }
  
  .imgClass {
    width: 3rem !important;
    display: flex;
    align-content: space-between;
    align-items: center;
    margin-left: 1rem;
  }
  
  .mediaViewOverlay {
    height: 61%;
    width: 61%;
    position: relative; /* Stay in place */
    background-color: rgb(255, 255, 0255);
    left: 21%;
    top: 21%;
  }
  
  .mediaView {
    height: 90%;
    width: 100%;
    position: relative; /* Stay in place */
    background-color: rgb(255, 255, 0255);
  }
  
  .deleteIcon {
    left: 97%;
    top: 0;
    position: relative;
    color: black;
    cursor: pointer;
    z-index: #999;
  }
  
  .imgView {
    top: 30%;
    left: 37%;
    position: sticky;
  }
  
  .enlargeImgView {
    top: 40%;
    left: 37%;
    position: sticky;
    width: 100%;
    height: 100%;
  }
  
  .overlayMpeg {
    position: absolute;
    width: 90%;
    top: 45%;
    left: 5%;
    transform: translateY(-45%);
    z-index: 1;
  }
  
  .overlay {
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.8); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }
  
  @media (min-width: 760px) and (max-width: 1080px) {
    .deleteIcon {
      left: 94%;

    } 
    .imgView {
      top: 45%;
      left: 32%;
      position: sticky;
    }
    .enlargeImgView {
      top: 44%;
      left: 34%;
      position: sticky;
      width: 100%;
      height: 100%;
    }
  
    .mediaView {
      background-color: rgba(255, 243, 205, var(--tw-bg-opacity));
    }
    .pdf-alert {
      margin-left: 0rem;
      width: 100%;
    }
  }
  
  @media (min-width: 820px) and (max-width: 1180px) {
    .mediaView {
      background-color: rgba(255, 243, 205, var(--tw-bg-opacity));
    }
  }
  
  @media (min-width: 375px) and (max-width: 800px) {
    .imgView {
      top: 45%;
      left: 32%;
      position: sticky;
    }
  
    .enlargeImgView {
      top: 45%;
      left: 50%;
      position: sticky;
      max-width: 100%;
      max-height: 100%;
    }

    .mediaView {
      background-color: rgba(255, 243, 205, var(--tw-bg-opacity));
    }
  }
  @media (min-width: 741px) and (max-width: 899px) {
    .imgName {
      flex-shrink: 0;
      inline-size: 35rem;
      word-break: break-word;
      height: inherit;
    }
  }
  @media (min-width: 360px) and (max-width: 740px) {
    .imgName {
      flex-shrink: 0;
      inline-size: 13rem;
      word-break: break-word;
      height: inherit;
    }
  
    .deleteIcon {
      left: 90%;
    }
    .mediaView {
      background-color: rgba(255, 243, 205, var(--tw-bg-opacity));
    }
  }
  
  @media (min-width: 900px) and (max-width: 1400px) {
    .imgName {
      flex-shrink: 0;
      inline-size: 40rem;
      word-break: break-word;
      height: inherit;
    }

  }
  .ctn-fht1 {

    min-height: 65vh;

}
  