.div-txt-newline {
    white-space: pre-line;
}
.textbox{
    width: 50%;
    height: 150px;
    border-radius: 0px;
}


.buttonCancel {
    color: #068089 !important;
    background-color: rgb(255, 255, 255) !important;
}
.buttonCancel:hover{
    background-color: transparent;
}
.buttonCreate {
    -tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(0, 155, 170, var(--tw-bg-opacity));
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
    min-width: 12.5rem;
    padding: 1rem;
    text-align: center;
}
.subject {
    border-radius: 0px;
    width : 50%;
}
.subject:focus{
    border-color: rgba(0, 155, 170, var(--tw-border-opacity))!important;
}
.buttonCreate:disabled {
    opacity: 0.5;
    pointer-events: none;
}
@media only screen and (max-width: 576px) {
    .css-gtwism-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
        padding: 6px;
        height: 60px !important;
    }
    .subject {
        width : 100%;
    }
}